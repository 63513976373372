import React, {useEffect} from "react";
import { Form } from "antd";
import SelectWithLabel from "../../../components/common/form/input-with-label/SelectWithLabel";
import { FormComponentProps } from "antd/lib/form";
import ButtonSearch from "../../../components/common/form/button/ButtonSearch";
import InputWithLabel from "../../../components/common/form/input-with-label/InputWithLabel";
import ButtonCreate from "../../../components/common/form/button/ButtonCreate";

interface Props extends FormComponentProps {}
const SearchForm: React.FC<Props> = props => {
    useEffect(() => {
        // eslint-disable-next-line
    }, []);
    return (
        <Form>
            <div className="input-group">
                <SelectWithLabel
                    options={{1: "Tổ chức", 2: "Cá nhân"}}
                    name="type"
                    wrappedClass="col-md-2 pl-0"
                    form={props.form}
                    placeholder="Loại khách hàng"
                    allowClear={true}
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="uid"
                    placeholder={"Mã khách hàng"}
                    wrapClass="col-md-2 pl-0"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="code"
                    placeholder={"Mã định danh"}
                    wrapClass="col-md-2 pl-0"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="fullname"
                    placeholder={"Tên khách hàng"}
                    wrapClass="col-md-2 pl-0"
                />
                <SelectWithLabel
                    options={{
                        1: "Chờ khách hàng ký",
                        2: "Khách hàng từ chối",
                        3: "Chờ nghiệp vụ duyệt",
                        4: "Nghiệp vụ từ chối",
                        5: "Chờ gen",
                        6: "Hoàn thành",
                    }}
                    name="status"
                    wrappedClass="col-md-2 pl-0"
                    form={props.form}
                    placeholder="Trạng thái"
                    allowClear={true}
                />
                <div className="form-group col-md-2 mt-1 pl-0">
                    <ButtonSearch data={props.form.getFieldsValue()} />
                </div>
            </div>
            <div className="input-group">
                <ButtonCreate toUrl={"/danh-sach-dieu-chinh-thong-tin/them-moi-yeu-cau-to-chuc"} className="mr-1 ant-btn bg-success text-white" text="Thêm mới yêu cầu tổ chức"/>
                <ButtonCreate toUrl={"/danh-sach-dieu-chinh-thong-tin/them-moi-yeu-cau-ca-nhan"} className="mr-1 ant-btn bg-success text-white" text="Thêm mới yêu cầu cá nhân"/>
            </div>
        </Form>
    );
};

const WrappedSearchForm = Form.create<Props>({
    name: "WrappedSearchForm"
})(SearchForm);

export default WrappedSearchForm;
