import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "qlbh/yeu-cau-chung-thu-so-to-chuc/";

const previewFile = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "previewFile",
        data,
        "POST"
    );
};

const getListIndex = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
};

const getListIndexSatff = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "index-staff",
        data,
        "GET"
    );
};

const store = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "store",
        data,
        "POST"
    );
};

const getRequestCertificate = async id => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getRequestCertificate/" + id,
        {},
        "GET"
    );
};

const getFile = async id => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getFile/" + id,
        {},
        "GET"
    );
};

const getFileRegister = async id => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getFileRegister/" + id,
        {},
        "GET"
    );
};

const previewRequest = async id =>{
    return await BaseServices.request(
        PREFIX_SERVICES + "preview-request/" + id,
        {},
        "GET"
    );
};

const update = async (id, data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "update",
        {request_id: id, ...data},
        "PUT"
    );
};

const deleteRequest = async (id, ) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "delete",
        {request_id: id},
        "DELETE"
    );
};

const denyRequest = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "deny-request",
        data,
        "POST"
    );
};

const approvalRequest = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "approval-request",
        data,
        "POST"
    );
};


const getInfo = async (code, object, idenType) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getInfo",
        {code, object, idenType},
        "POST"
    );
};

const uploadFile = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "upload-file",
        data,
        "POST"
    );
};

const getFileDoc = async (id , type) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getFileDoc",
        {id, type},
        "POST"
    );
};

const getProvince =  () =>{
    return BaseServices.request(
        PREFIX_SERVICES + 'getProvince',
        {},
        "GET"
    )
};
const getDistrictByProvince = async (provinceCode) => {
    return await BaseServices.request(
        PREFIX_SERVICES + 'getDistrictByProvinceCode',
        {provinceCode},
        "GET"
    );
};
const getRequestBySerial = async (serial_cts, identity_code) => {
    return await BaseServices.request(
        PREFIX_SERVICES + "getRequestBySerial",
        {serial_cts, identity_code},
        "GET"
    );
};
const getAllProvider = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + "all-provider",
        {},
        "GET"
    );
};
const getListCateServicePackage = async (type_search,type, obj,type_device, provider_id = '', hsm_provider='') => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-cate-service-package",
        {type_search, type, obj, type_device, provider_id, hsm_provider},
        "GET"
    );
};
const getVersionToken = async () => {
    return await BaseServices.request(
        PREFIX_SERVICES + "get-version-token",
        {},
        "GET"
    );
};

const getListUserByLevel = async (data: object) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `getListUserByLevel`,
        data,
        "POST"
    );
};
const checkCertInFileSigned = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `check-cert-in-file-signed`,
        data,
        "POST"
    );
};
const checkSerial = async (data) => {
    return await BaseServices.request(
        PREFIX_SERVICES + `check-serial`,
        data,
        "GET"
    );
};

export const RequestCertificateGroupService = {
    previewFile,
    getListIndex,
    getListIndexSatff,
    store,
    getRequestCertificate,
    previewRequest,
    update,
    deleteRequest,
    denyRequest,
    approvalRequest,
    getFile,
    getFileRegister,
    getInfo,
    uploadFile,
    getFileDoc,
    getProvince,
    getDistrictByProvince,
    getRequestBySerial,
    getAllProvider,
    getListCateServicePackage,
    getVersionToken,
    getListUserByLevel,
    checkCertInFileSigned,
    checkSerial
};