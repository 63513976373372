import React, { useEffect, useState } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import { Card, Checkbox, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import { loading } from "components/common/loading/Loading";
import _ from "lodash";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import moment from "moment";
import {
    formatMoney,
    handleDateData,
    handleFormatString,
} from "../../helpers/NewCaCrmHelper";
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
// import BaseServices from "../../services/base/BaseServices";

interface Props extends FormComponentProps {
    user: any;
    history: any;
}

const objectDate1 = {
    1: "represen_passport_date",
};
const objectDate2 = {
    1: "provide_date",
};
const objectDate3 = {
    1: "date_expired_cert",
};

export const RequestCertificateGroup: React.FC<Props> = (props) => {
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [provider, setProvider] = useState({});
    const [versionToken, setVerionToken] = useState({});
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState(
        []
    );
    const [disableCode] = useState(false);
    const [disableObject, setDisableObject] = useState(true);
    const [typeObject, setTypeObject] = useState(Number);
    const [nameFileDK01] = useState("");
    const [changeSetField, setChangeSetField] = useState(false);

    const [file, setFile] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [requiredTokenType, setRequiredTokenType] = useState(false);
    const [requiredSerialCts, setRequiredSerialCts] = useState(false);

    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [disableTaxCode, setDissableTaxCode] = useState(false);
    const [disableIdenType] = useState(false);
    const [disableField, setDisableField] = useState(false);
    const [disableTypeDevice, setDisableTypeDevice] = useState(false);
    const [disableHSMProvider, setDisableHSMProvider] = useState(false);
    const [typeUpload, setTypeUpload] = useState(1);
    const [showEmail, setShowEmail] = useState(false);
    const [requireFileWhenExtend, setRequireFileWhenExtend] = useState(true);


    const storeRequest = (status) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const val = {
                        ...values,
                        status,
                        showEmail,
                    };

                    let valuesConvert = val;
                    if (
                        val.represen_passport_date !== undefined &&
                        val.represen_passport_date !== null
                    ) {
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (
                        val.provide_date !== undefined &&
                        val.provide_date !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate2
                        );
                    }
                    if (
                        val.date_expired_cert !== undefined &&
                        val.date_expired_cert !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate3
                        );
                    }

                    const data = await RequestCertificateGroupService.store(
                        valuesConvert
                    );
                    if (data && Number(data.status) === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())],
                                },
                            });
                        });
                    } else if (data && Number(data.status) === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-cts-to-chuc");
                        });
                    } else {
                        onFailAction("Có lỗi xảy ra khi lưu !");
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi lưu !");
                } finally {
                    loading.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onPreviewFile = async (typeFile) => {
        const { validateFields } = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const val = {
                        ...values,
                        typeFile: typeFile,
                    };

                    let valuesConvert = val;
                    if (
                        val.represen_passport_date !== undefined &&
                        val.represen_passport_date !== null
                    ) {
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (
                        val.provide_date !== undefined &&
                        val.provide_date !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate2
                        );
                    }
                    if (
                        val.date_expired_cert !== undefined &&
                        val.date_expired_cert !== null
                    ) {
                        valuesConvert = handleDateData(
                            valuesConvert,
                            objectDate3
                        );
                    }
                    const data =
                        await RequestCertificateGroupService.previewFile(
                            valuesConvert
                        );
                    setFile(data.base64);
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onOkModal = () => {
        setVisibleModal(false);
        setFile("");
    };

    const changeObject = async (e) => {
        if (props.form.getFieldValue("code").trim() === "") {
            onFailAction("Bạn chưa nhập định danh.");
        }
        setTypeObject(e.target.value);
        let code = props.form.getFieldValue("code");
        let idenType = props.form.getFieldValue("identity_type");
        let object = e.target.value;
        if (Number(object) === 2) {
            setRequiredTokenType(true);
            setRequiredSerialCts(true);
        } else if (Number(object) === 3) {
            setRequiredTokenType(false);
            setRequiredSerialCts(false);
        }
        await fetchInfo(code, object, idenType);

        setTypeObject(props.form.getFieldValue("object"));
        let type_search = 4;
        let type = props.form.getFieldValue("object");
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = props.form.getFieldValue("hsm_provider");
        let obj = 3;
        let type_device = props.form.getFieldValue("type_device");
        const catePackage = await getCateServicePackage(
            type_search,
            type,
            obj,
            type_device,
            provider_id,
            hsm_provider
        );
        setCateServicePackageAmount(catePackage.data);
        setCateServicePackage(
            _.mapValues(_.keyBy(catePackage.data, "id"), "name")
        );
        if (Number(e.target.value) === 2) {
            setRequireFileWhenExtend(false);
        }else{
            setRequireFileWhenExtend(true);
        }
    };

    const getCateServicePackage = async (
        type_search,
        type,
        obj,
        type_device,
        provider_id,
        hsm_provider
    ) => {
        return await RequestCertificateGroupService.getListCateServicePackage(
            type_search,
            type,
            obj,
            type_device,
            provider_id,
            hsm_provider
        );
    };
    const changeTypeDevice = async (e) => {
        const type_search = 4;
        const type = typeObject;
        let obj = 3;
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = props.form.getFieldValue("hsm_provider");
        const type_device = e.target.value;
        const data =
            await RequestCertificateGroupService.getListCateServicePackage(
                type_search,
                type,
                obj,
                type_device,
                provider_id,
                hsm_provider
            );
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: "",
            package_price: "",
        });
    };
    const onChangeHSMProvider = async (e) => {
        const type_search = 4;
        const type = typeObject;
        let obj = 3;
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = e.target.value;
        const type_device = props.form.getFieldValue("type_device");
        const data =
            await RequestCertificateGroupService.getListCateServicePackage(
                type_search,
                type,
                obj,
                type_device,
                provider_id,
                hsm_provider
            );
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: "",
            package_price: "",
        });
    };
    const changeCode = async (e) => {
        if (e.target.value) {
            setDisableObject(false);
        } else {
            setDisableObject(true);
            props.form.setFields({ object: "" });
        }
        props.form.resetFields([
            "tax_code",
            "object",
            "fullname",
            "address",
            "phone",
            "email",
            "province_code",
            "district_code",
        ]);
    };
    const onChangeProvider = (e) => {
        props.form.resetFields(["type_device"]);
    };
    const fetchInfo = async (code, object, idenType) => {
        loading.runLoadingBlockUI();
        const result = await RequestCertificateGroupService.getInfo(
            code,
            object,
            idenType
        );
        if (result && result.status === 200 &&  result.data.length !== 0) {
            if (Number(object) === 1 || Number(object) === 3) {
                props.form.setFieldsValue({
                    type_legal: result.data.type_legal.toString(),
                    identity_type: result.data.identity_type.toString(),
                    code: result.data.code,
                    provide_organization: result.data.provide_organization,
                    tax_code: result.data.tax_code,
                    fullname: result.data.fullname,
                    address: result.data.address,
                    email: result.data.email,
                    phone: result.data.phone,
                    type_device: result.data.request.type_device.toString(),
                    represent_fullname: result.data.represent_fullname,
                    represen_passport: result.data.represen_passport,
                    represen_passport_place:
                        result.data.represen_passport_place,
                    represen_position: result.data.represen_position,
                    represen_email: result.data.represen_email,
                    represen_phone: result.data.represen_phone,
                    provider_id: result.data.request.provider_id.toString(),
                });
                if (result.data.represen_passport_date) {
                    props.form.setFieldsValue({
                        represen_passport_date: moment(
                            result.data.represen_passport_date
                        ),
                    });
                }
                if (result.data.provide_date) {
                    props.form.setFieldsValue({
                        provide_date: moment(result.data.provide_date),
                    });
                }
            }
        } else if (result && result.status === 100 && result.data.length !== 0) {
            getProvince();
            const district =
                await RequestCertificateGroupService.getDistrictByProvince(
                    result.data.province_code
                );
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
            props.form.setFieldsValue({
                fullname: result.data.fullname,
                address: result.data.address,
            });
            if (
                result.data.province_code !== null &&
                result.data.district_code !== null
            ) {
                props.form.setFieldsValue({
                    province_code: result.data.province_code.toString(),
                    district_code: result.data.district_code.toString(),
                });
            } else {
                props.form.setFieldsValue({
                    province_code: "",
                    district_code: "",
                });
            }
        }
        if (Number(object) === 2) {
            if (Number(result.status) === 100) {
                props.form.setFieldsValue({
                    fullname: result.data.fullname,
                    address: result.data.address,
                    phone: result.data.phone,
                    email: result.data.email,
                });
                if (
                    result.data.province_code !== null &&
                    result.data.district_code !== null
                ) {
                    props.form.setFieldsValue({
                        province_code: result.data.province_code.toString(),
                        district_code: result.data.district_code.toString(),
                    });
                } else {
                    props.form.setFieldsValue({
                        province_code: "",
                        district_code: "",
                    });
                }
            }
            props.form.setFieldsValue({ serial_cts: "" });
        }
        props.form.resetFields(["package_id", "package_price"]);
        loading.stopRunLoading();
    };

    const getProvince = async () => {
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) => {
        if (e) {
            const district =
                await RequestCertificateGroupService.getDistrictByProvince(e);
            setDistrict(
                _.mapValues(
                    _.keyBy(district, "newtel_district_code"),
                    "fullname"
                )
            );
        }
        props.form.setFieldsValue({
            district_code: undefined,
        });
    };
    const onChangeAdjourn = async (e) => {
        const serialCert = handleFormatString(
            e.target.value.replace(/ /g, ""),
            3
        );
        props.form.setFieldsValue({ serial_cts: serialCert });
        if (e) {
            if (Number(props.form.getFieldValue("provider_id")) === 1) {
                const identity_code = props.form.getFieldValue("code");
                if (Number(props.form.getFieldValue("object")) === 2) {
                    loading.runLoadingBlockUI();
                    const dataRequestCert =
                        await RequestCertificateGroupService.getRequestBySerial(
                            serialCert,
                            identity_code
                        );
                    if (dataRequestCert.status === 200) {
                        if (
                            dataRequestCert.data.status === 1 ||
                            dataRequestCert.data.status === 9
                        ) {
                            setDisableField(false);
                            props.form.setFieldsValue({
                                serial_cts: serialCert,
                                type_legal:
                                    dataRequestCert.data.requestcertificate.organization.type_legal.toString(),
                                code: dataRequestCert.data.requestcertificate
                                    .organization.code,
                                provide_organization:
                                    dataRequestCert.data.requestcertificate
                                        .organization.provide_organization,
                                tax_code:
                                    dataRequestCert.data.requestcertificate
                                        .organization.tax_code,
                                fullname:
                                    dataRequestCert.data.requestcertificate
                                        .organization.fullname,
                                address:
                                    dataRequestCert.data.requestcertificate
                                        .organization.address,
                                email: dataRequestCert.data.requestcertificate
                                    .organization.email,
                                phone: dataRequestCert.data.requestcertificate
                                    .organization.phone,

                                type_device:
                                    dataRequestCert.data.requestcertificate.type_device.toString(),
                                provider_id:
                                    dataRequestCert.data.requestcertificate.provider_id.toString(),
                                represent_fullname:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represent_fullname,
                                represen_passport:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represen_passport,
                                represen_passport_place:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represen_passport_place,
                                represen_position:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represen_position,
                                represen_email:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represen_email,
                                represen_phone:
                                    dataRequestCert.data.requestcertificate
                                        .organization.represen_phone,
                                // sp_fullname: dataRequestCert.data.requestcertificate.requestsupport.fullname,
                                // sp_position: dataRequestCert.data.requestcertificate.requestsupport.position,
                                // sp_email: dataRequestCert.data.requestcertificate.requestsupport.email,
                                // sp_phone: dataRequestCert.data.requestcertificate.requestsupport.phone,
                            });

                            if (
                                dataRequestCert.data.requestcertificate
                                    .organization.represen_passport_date
                            ) {
                                props.form.setFieldsValue({
                                    represen_passport_date: moment(
                                        dataRequestCert.data.requestcertificate
                                            .organization.represen_passport_date
                                    ),
                                });
                            }
                            if (
                                dataRequestCert.data.requestcertificate
                                    .organization.provide_date
                            ) {
                                props.form.setFieldsValue({
                                    provide_date: moment(
                                        dataRequestCert.data.requestcertificate
                                            .organization.provide_date
                                    ),
                                });
                            }
                            setDisableTypeDevice(true);
                            setDisableHSMProvider(true);
                            let type_search = 4;
                            let type = props.form.getFieldValue("object");
                            let provider_id =
                                props.form.getFieldValue("provider_id");
                            let hsm_provider =
                                props.form.getFieldValue("hsm_provider");
                            let obj = 3;
                            let type_device =
                                dataRequestCert.data.requestcertificate.type_device.toString();
                            const catePackage = await getCateServicePackage(
                                type_search,
                                type,
                                obj,
                                type_device,
                                provider_id,
                                hsm_provider
                            );
                            setCateServicePackageAmount(catePackage.data);
                            setCateServicePackage(
                                _.mapValues(
                                    _.keyBy(catePackage.data, "id"),
                                    "name"
                                )
                            );
                        }
                    }
                    if (Number(dataRequestCert.status) === 102 || dataRequestCert.status === 101) {
                        onFailAction(
                            "Serial chứng thư số không hợp lệ!",
                            () => {
                                props.form.setFieldsValue({
                                    serial_cts: "",
                                });
                            },
                            "warning",
                            ""
                        );
                    }
                    if (dataRequestCert.status === 103) {
                        if (
                            dataRequestCert.data.status === 1 ||
                            dataRequestCert.data.status === 9
                        ) {
                            setDisableField(false);
                            props.form.setFieldsValue({
                                type_device:
                                    dataRequestCert.data.requestcertificate.type_device.toString(),
                            });
                            setDisableTypeDevice(true);
                            setDisableHSMProvider(true);
                            let type_search = 4;
                            let type = props.form.getFieldValue("object");
                            let provider_id =
                                props.form.getFieldValue("provider_id");
                            let hsm_provider =
                                props.form.getFieldValue("hsm_provider");
                            let obj = 3;
                            let type_device =
                                dataRequestCert.data.requestcertificate.type_device.toString();
                            const catePackage = await getCateServicePackage(
                                type_search,
                                type,
                                obj,
                                type_device,
                                provider_id,
                                hsm_provider
                            );
                            setCateServicePackageAmount(catePackage.data);
                            setCateServicePackage(
                                _.mapValues(
                                    _.keyBy(catePackage.data, "id"),
                                    "name"
                                )
                            );
                        }
                    }
                    if (dataRequestCert.status === 104) {
                        setDisableField(false);
                        props.form.setFieldsValue({
                            type_device: "1",
                        });
                        setDisableTypeDevice(true);
                        setDisableHSMProvider(true);
                        let type_search = 4;
                        let type = props.form.getFieldValue("object");
                        let provider_id =
                            props.form.getFieldValue("provider_id");
                        let hsm_provider =
                            props.form.getFieldValue("hsm_provider");
                        let obj = 3;
                        let type_device = 1;
                        const catePackage = await getCateServicePackage(
                            type_search,
                            type,
                            obj,
                            type_device,
                            provider_id,
                            hsm_provider
                        );
                        setCateServicePackageAmount(catePackage.data);
                        setCateServicePackage(
                            _.mapValues(_.keyBy(catePackage.data, "id"), "name")
                        );
                    }
                } else if (Number(props.form.getFieldValue("object")) === 3) {
                    const dataSend = {
                        serialCert: serialCert,
                    };
                    loading.runLoadingBlockUI();
                    const checkSerial =
                        await RequestCertificateGroupService.checkSerial(
                            dataSend
                        );
                    if (checkSerial.status !== 200) {
                        onFailAction(
                            checkSerial.message,
                            () => {
                                props.form.setFieldsValue({
                                    serial_cts: "",
                                });
                            },
                            "warning",
                            ""
                        );
                    }
                }
            }
        }
    };
    const onChangeCodeTaxCode = async (e) => {
        if (e && Number(props.form.getFieldValue("identity_type")) === 1) {
            props.form.setFieldsValue({
                tax_code: e.target.value,
            });
        }
    };
    const onChangeIdenType = async (e) => {
        if (e) {
            props.form.resetFields([
                "tax_code",
                "code",
                "object",
                "fullname",
                "address",
                "phone",
                "email",
                "province_code",
                "district_code",
            ]);
            if (Number(e) === 1) {
                setDissableTaxCode(true);
                setLabelCode("Mã số thuế");
            }
            if (Number(e) === 2) {
                setDissableTaxCode(false);
                setLabelCode("Mã ngân sách");
            }
            if (Number(e) === 3) {
                setDissableTaxCode(false);
                setLabelCode("Số quyết định");
            }
            if (Number(e) === 4) {
                setDissableTaxCode(false);
                setLabelCode("Mã bảo hiểm xã hội");
            }
            if (Number(e) === 5) {
                setDissableTaxCode(false);
                setLabelCode("Giấy phép đầu tư");
            }
        }
    };
    const getProvider = async () => {
        const data = await RequestCertificateGroupService.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    const onChangeFormatInput = (name, value, type) => {
        const strFormart = handleFormatString(value, type);
        props.form.setFieldsValue({ [name]: strFormart });
    };
    const onChangePackage = async (value) => {
        const selectdCate: any = _.find(cateServicePackageAmount, {
            id: parseInt(value),
        });
        if (selectdCate) {
            const price = formatMoney(selectdCate.price);
            props.form.setFieldsValue({ package_price: price });
        }
    };
    const onChangePrice = (e) => {
        let priceStr = e.target.value;
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line
        if (format.test(priceStr)) {
            priceStr = priceStr.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""); //eslint-disable-line
        }

        const price = formatMoney(priceStr);
        props.form.setFieldsValue({ package_price: price });
    };
    const onChangeRepresentName = (e) => {
        if (e) {
            props.form.setFieldsValue({
                sp_fullname: e.target.value,
            });
        }
        props.form.setFieldsValue({
            represent_fullname: "",
        });
    };
    const onChangeRepresentPosition = (e) => {
        if (e) {
            props.form.setFieldsValue({
                sp_position: e.target.value,
            });
        }
        props.form.setFieldsValue({
            represen_position: "",
        });
    };
    const onChangeRepresentEmail = (e) => {
        if (e) {
            props.form.setFieldsValue({
                sp_email: e.target.value,
            });
        }
        props.form.setFieldsValue({
            represen_email: "",
        });
    };
    const onChangePromotionCode = (e) => {
        if (e) {
            props.form.setFieldsValue({
                promotion_code: e.target.value,
            });
        }
    };
    const onChangeRepresentPhone = (e) => {
        if (e) {
            props.form.setFieldsValue({
                sp_phone: e.target.value,
            });
        }
        props.form.setFieldsValue({
            represen_phone: "",
        });
    };
    useEffect(() => {
        getProvince();
        getProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);
    const onChangeTypeUpload = (e) => {
        if (e) {
            const type_upload = parseInt(e.target.value);
            setTypeUpload(type_upload);
        }
    };
    const onChangeTypeDoc = (e) => {
        if (
            Number(e.target.value) === 1 &&
            (Number(props.form.getFieldValue("object")) === 2 ||
                Number(props.form.getFieldValue("object")) === 3)
        ) {
            setChangeSetField(true);
        } else {
            setChangeSetField(false);
        }
    };
    const onChangeCheckBox = (e) => {
        setShowEmail(e.target.checked);
    };
    const onBlurEmail = (e) => {
        props.form.setFieldsValue({
            represen_email: e.target.value,
        });
    };
    const onBlurPhone = (e) => {
        props.form.setFieldsValue({
            represen_phone: e.target.value,
        });
    };
    return (
        <PageWrapper title="Yêu cầu chứng thư số tổ chức">
            <ModalDisplayFile
                titleModal="File DK-02"
                visibleModal={visibleModal}
                loadingModal={loadingModal}
                fileBase64={file}
                onOkModal={() => onOkModal()}
            />
            <InputWithLabel
                wrapClass={""}
                name={"isUpdate"}
                label={""}
                form={props.form}
                defaultValue={1}
                hidden={true}
            />
            <Form>
                <Card
                    className="container-fluid "
                    title={<label>Thông tin gói chứng thư số</label>}
                    size="small"
                >
                    <div className="input-group">
                        <SelectWithLabel
                            options={provider}
                            name="provider_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Nhà cung cấp"}
                            isRequired={true}
                            isDisabled={disableField}
                            defaultValue={"1"}
                            onChange={onChangeProvider}
                        />
                        <SelectWithLabel
                            options={{
                                1: "Mã số thuế",
                                2: "Mã ngân sách",
                                3: "Số quyết định",
                                4: "Mã bảo hiểm xã hội",
                                5: "Giấy phép đầu tư",
                            }}
                            name="identity_type"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            onChange={onChangeIdenType}
                            isDisabled={disableIdenType}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            isRequired={true}
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            onChange={changeCode}
                            isDisabled={disableCode}
                            onBlur={onChangeCodeTaxCode}
                        />
                        <RadioWithLabel
                            options={{
                                1: "Cấp mới",
                                2: "Gia hạn",
                                3: "Chuyển đổi",
                            }}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-3 col-lg-4 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeObject}
                            isDisabled={disableObject}
                        />
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <SelectDateWithLabel
                                name="date_expired_cert"
                                form={props.form}
                                wrapClass="col-md-2 col-lg-2"
                                label="Ngày hết hạn"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Token",
                                2: "HSM",
                                // 3:"FastCA RS"
                            }}
                            label="Thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeTypeDevice}
                            isDisabled={disableTypeDevice}
                        />

                        {Number(props.form.getFieldValue("type_device")) ===
                        2 ? (
                            <RadioWithLabel
                                options={{ 1: "CyberLotus", 2: "Khác" }}
                                label="Nhà cung cấp HSM"
                                name="hsm_provider"
                                wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                form={props.form}
                                isRequired={true}
                                onChange={onChangeHSMProvider}
                                isDisabled={disableHSMProvider}
                            />
                        ) : (
                            ""
                        )}
                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isRequired={true}
                            onChange={onChangePackage}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-6 col-lg-2"
                            isRequired={true}
                            isDisabled={disableField}
                            onBlur={onChangePrice}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-6 col-lg-1"
                            isRequired={true}
                            type={"number"}
                            defaultValue={1}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isRequired={false}
                            onChange={onChangePromotionCode}
                        />
                    </div>
                    <div className="input-group">
                        {Number(props.form.getFieldValue("provider_id")) ===
                            2 &&
                        Number(props.form.getFieldValue("type_device")) ===
                            1 ? (
                            <SelectWithLabel
                                options={versionToken}
                                name="version_token_newtel"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Version token"}
                                isRequired={true}
                                isDisabled={disableField}
                            />
                        ) : (
                            ""
                        )}
                        {Number(props.form.getFieldValue("object")) === 2 ||
                        Number(props.form.getFieldValue("object")) === 3 ? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-6 col-lg-3"
                                    isRequired={requiredSerialCts}
                                    isDisabled={false}
                                    onBlur={onChangeAdjourn}
                                />
                                <SelectWithLabel
                                    options={{ 1: "Mới", 2: "Cũ" }}
                                    name="token_type"
                                    wrappedClass="col-md-6 col-lg-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isRequired={requiredTokenType}
                                    isDisabled={disableField}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="input-group">
                        <div className={"col-md-12"}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>
                                    Hiển thị Email
                                </Checkbox>
                                <span className={"text-danger font-italic"}>
                                    ( Chú ý: Nếu chọn Email sẽ hiển thị trong
                                    thông tin CTS )
                                </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Thông tin doanh nghiệp</label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Giấy ĐKKD",
                                2: "Giấy phép đầu tư",
                                3: "Quyết định thành lập",
                            }}
                            label="Giấy tờ pháp lý"
                            name="type_legal"
                            wrappedClass="col-md-6 col-lg-5 select-doi-tuong"
                            form={props.form}
                            isRequired={requireFileWhenExtend}
                            isDisabled={disableField}
                        />
                        <InputFileUpload
                            nameFiledSetValue={""}
                            classWrapped="col-md-6 col-lg-3"
                            label="Tải file giấy tờ pháp lý"
                            name="file_legal"
                            form={props.form}
                            isRequired={requireFileWhenExtend}
                            extentionsAllow={["pdf", "PDF", "png", "jpg"]}
                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            isDisabled={disableField}
                            note={true}
                        />
                        <SelectDateWithLabel
                            name="provide_date"
                            form={props.form}
                            wrapClass="col-md-6 col-lg-2"
                            label="Ngày cấp"
                            rules={[
                                {
                                    validator: function (
                                        rule,
                                        value,
                                        callback
                                    ) {
                                        if (value && value > moment()) {
                                            callback(
                                                "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                            );
                                        } else {
                                            callback();
                                        }
                                    },
                                    message:
                                        "Ngày cấp phải nhỏ hơn ngày hiện tại",
                                },
                            ]}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="provide_organization"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                    </div>
                    {/*line2*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="MST (nếu có)"
                            name="tax_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={disableTaxCode}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên doanh nghiệp"
                            name="fullname"
                            wrapClass="col-md-6 col-lg-4"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            onBlur={(e) =>
                                onChangeFormatInput(
                                    "fullname",
                                    e.target.value,
                                    3
                                )
                            }
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-6 col-lg-2"
                            isRequired={true}
                            maxLength={16}
                            isDisabled={disableField}
                            onBlur={onBlurPhone}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-6 col-lg-4"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            onBlur={onBlurEmail}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-8"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            isProvince={true}
                            onChange={onChangeProvince}
                            isDisabled={disableField}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                            isDisabled={disableField}
                        />
                        <div className="font-size-12 text-danger col-12">
                            Lưu ý: Thông tin Số điện thoại và Email bắt buộc
                            nhập chính xác để xác minh khách hàng và nhận hỗ trợ
                            dịch vụ từ NCC
                        </div>
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Người đại diện</label>}
                    size="small"
                >
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="represent_fullname"
                            wrapClass="col-md-6 col-lg-3"
                            isRequired={true}
                            maxLength={255}
                            isDisabled={disableField}
                            onBlur={(e) =>
                                onChangeFormatInput(
                                    "represent_fullname",
                                    e.target.value,
                                    1
                                )
                            }
                            onChange={onChangeRepresentName}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số CMND"
                            name="represen_passport"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={16}
                            isDisabled={disableField}
                        />
                        <SelectDateWithLabel
                            name="represen_passport_date"
                            form={props.form}
                            wrapClass="col-md-6 col-lg-3"
                            label="Ngày cấp"
                            rules={[
                                {
                                    validator: function (
                                        rule,
                                        value,
                                        callback
                                    ) {
                                        if (value && value > moment()) {
                                            callback(
                                                "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                            );
                                        } else {
                                            callback();
                                        }
                                    },
                                    message:
                                        "Ngày cấp phải nhỏ hơn ngày hiện tại",
                                },
                            ]}
                            isDisabled={disableField}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="represen_passport_place"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                            isDisabled={disableField}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="represen_position"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                            isDisabled={disableField}
                            onChange={onChangeRepresentPosition}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="represen_email"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                            isDisabled={disableField}
                            isRequired={false}
                            onChange={onChangeRepresentEmail}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="represen_phone"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={16}
                            isRequired={false}
                            isDisabled={disableField}
                            onChange={onChangeRepresentPhone}
                        />
                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Upload 1 file", 2: "Upload 2 file" }}
                            label="Hình thức upload"
                            name="passport_upload_type"
                            wrappedClass="col-lg-3 col-md-6"
                            form={props.form}
                            isRequired={requireFileWhenExtend}
                            isDisabled={disableField}
                            onChange={onChangeTypeUpload}
                        />

                        {typeUpload === 1 ? (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-6 col-lg-3 white-space-initial"
                                label={"Tải file CMND/Hộ chiếu"}
                                name={"file_deputy_passport"}
                                form={props.form}
                                isRequired={requireFileWhenExtend}
                                extentionsAllow={[
                                    "pdf",
                                    "PDF",
                                    "png",
                                    "jpeg",
                                    "jpg",
                                    "PNG",
                                    "JPG",
                                    "JPEG",
                                ]}
                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                isDisabled={disableField}
                                isPassport={true}
                                note={true}
                            />
                        ) : (
                            <div className="form-group col-md-6 col-lg-6 d-flex">
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-6 no-padding-left"
                                    label={"CMND (Mặt trước)"}
                                    name={"passport_front_side"}
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    extentionsAllow={[
                                        "png",
                                        "jpg",
                                        "jpeg",
                                        "PNG",
                                        "JPG",
                                        "JPEG",
                                    ]}
                                    accept={[".png", ".jpg", ".jpeg"]}
                                    isDisabled={disableField}
                                    isPassport={true}
                                    note={true}
                                />
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-6 no-padding-right"
                                    label="CMND (Mặt sau)"
                                    name="passport_back_side"
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    isDisabled={disableField}
                                    extentionsAllow={[
                                        "png",
                                        "jpg",
                                        "jpeg",
                                        "JPG",
                                        "PNG",
                                        "JPEG",
                                    ]}
                                    accept={[".png", ".jpg", ".jpeg"]}
                                    isPassport={true}
                                    note={true}
                                />
                            </div>
                        )}
                    </div>
                </Card>
                <Card
                    className="container-fluid mt-4"
                    title={<label>Mẫu đăng ký</label>}
                    size="small"
                >
                    <div className="input-group">
                        <RadioWithLabel
                            options={{ 1: "Điện tử", 2: "Giấy", 3: "Scan" }}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong "
                            form={props.form}
                            isRequired={true}
                            isDisabled={disableField}
                            onChange={onChangeTypeDoc}
                        />
                        <div className="col-md-6 col-lg-3 mb-2">
                            <label className="invisible label-hide ">
                                11111998
                            </label>
                            <button
                                onClick={() => onPreviewFile(1)}
                                className="btn btn-outline-success form-control"
                            >
                                Tải xuống DK-01.01
                            </button>
                        </div>
                        {Number(props.form.getFieldValue("provider_id")) ===
                        2 ? (
                            <div className="col-md-6 col-lg-3 mb-2">
                                <label className="invisible label-hide ">
                                    11111998
                                </label>
                                <button
                                    onClick={() => onPreviewFile(2)}
                                    className="btn btn-outline-primary form-control"
                                >
                                    Tải xuống DK-02
                                </button>
                            </div>
                        ) : (
                            ""
                        )}

                        {/*<div className="col-md-6 col-lg-3 mb-2">*/}
                        {/*    <label className="invisible label-hide ">11111998</label>*/}
                        {/*    <button onClick={() => onPreviewFile(3)} className="btn btn-outline-primary form-control">*/}
                        {/*        Tải xuống DK-03*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                    <div className="input-group m-t-xs-4">
                        <InputFileUpload
                            classWrapped="col-md-6 col-lg-3"
                            label="Tải file DK-01.01 (Đã ký)"
                            name="file_register_paper"
                            form={props.form}
                            extentionsAllow={["pdf", "PDF", "png", "jpg"]}
                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            note={true}
                            defaultLabel={nameFileDK01}
                            onChangeSetField={changeSetField}
                            nameFiledSetValue={"date_expired_cert"}
                            isRequired={true}
                        />
                        {Number(props.form.getFieldValue("provider_id")) ===
                        2 ? (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-6 col-lg-3"
                                label="Tải file DK-02 (Nếu có)"
                                name="file_dk_02"
                                form={props.form}
                                extentionsAllow={["pdf", "PDF", "png", "jpg"]}
                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                note={true}
                            />
                        ) : (
                            ""
                        )}

                        {/*<InputFileUpload*/}
                        {/*    nameFiledSetValue={""}*/}
                        {/*    classWrapped="col-md-6 col-lg-3"*/}
                        {/*    label="Tải file DK-03 (Đã ký)"*/}
                        {/*    name="file_dk_03"*/}
                        {/*    form={props.form}*/}
                        {/*    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}*/}
                        {/*    accept={[".pdf", ".png", ".jpg", ".jpeg"]}*/}
                        {/*    isDisabled={disableField}*/}
                        {/*    note={true}*/}
                        {/*/>*/}
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-2">
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest(1);
                        }}
                        label="Lưu nháp"
                        className={"btn btn-primary btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            storeRequest(2);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonCancel
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCertificateGroupCreate = Form.create<Props>({
    name: "RequestCertificateGroup",
})(RequestCertificateGroup);

export default WrappedRequestCertificateGroupCreate;
