import React, { useState } from "react";
import PageWrapper from "../wrapper/PageWrapper";
import Table from "../../components/common/table/Table";
import { onFailAction, onSuccessAction } from "helpers/SwalCommon";
import { RouteComponentProps } from "react-router";
import {
  formatDateTime,
  reloadPage,
  formatDateNotTime,
} from "helpers/NewCaCrmHelper";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import { RequestCertificateGroupService } from "../../services/request-certificate-group/RequestCertificateGroupServices";
import { STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL } from "../../helpers/enum/request-digital-certificate/RequestDigitalCertificateEnums";
import ModalUploadFile from "../../components/common/modal/upload-file/ModalUploadFile";
import { loading } from "../../components/common/loading/Loading";
import BaseServices from "../../services/base/BaseServices";
import WrappedRequestCTSGroupSearchForm from "./search/RequestCTSGroupSearchForm";
import {Badge, Icon, Tag, Tooltip} from "antd";
import store from "../../store/store";
import {
    REQUEST_CERTIFICATE_ORGANIZATION_PERMISSION,
} from "../../helpers/enum/PermissionEnums";

interface Props extends RouteComponentProps {
}

export const RequestCertificateGroup: React.FC<Props> = props => {
    const user = store.getState().authReducer;

    const onDeleteRequestDigital = async id => {
        const { location, history } = props;
        try {
            await RequestCertificateGroupService.deleteRequest(id);
            reloadPage(location, history);
            onSuccessAction("Xóa yêu cầu thành công!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xóa yêu cầu!");
        }
    };

    const renderActionButton = (text, record, index) => {
        if (text.created_by === user.id) {
            return (
                <TableActionButton
                    permissionUpdate=""
                    permissionPreview= {REQUEST_CERTIFICATE_ORGANIZATION_PERMISSION.PREVIEW}
                    // permissionDelete={true}
                    onClickUpdate={
                        [1, 3, 7].indexOf(record.status) !== -1
                            ? () => props.history.push(`yeu-cau-cts-to-chuc/cap-nhat/${record.id}`)
                            : null
                    }
                    onClickDelete={
                        [1, 3, 7].indexOf(record.status) !== -1
                            ? () => onDeleteRequestDigital(record.id)
                            : null
                    }
                    onClickPreviewButton={() =>
                        props.history.push(`yeu-cau-cts-to-chuc/xem/${record.id}`)
                    }
                    // onClickUpload={
                    //     [2].indexOf(record.file_status) !== -1
                    //         ? () => importClick(record.id)
                    //         : null
                    // }
                />
            );
        }
    };

    const renderStatusColumn = (text, record, index) => {
        if (Number(record.status) === 6 && record.is_expired_gen){
            return (
                <Tooltip title={"Mã token: "+record.token_code} placement="bottomLeft">
                    <Tooltip title={(record.nv_approve_at && record.is_expired_gen) ? record.is_expired_gen : "Yêu cầu chỉ có 15 ngày để gen kể từ ngày duyệt."} placement="topLeft">
                        <Badge count={<Icon type="warning" style={{ color: '#f5222d' }} />}>
                            <Tag color={`${STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].class}`}>
                                {STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].label}
                            </Tag>
                        </Badge>
                    </Tooltip>
                </Tooltip>

            );
        }else {
            return (
                <Tooltip title={"Mã token: "+record.token_code} placement="topRight">
                    <Tag color={`${STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].class}`}>
                        {STATUS_REQUEST_DIGITAL_CERTIFICATE_LABEL[text].label}
                    </Tag>
                </Tooltip>
                    );
        }

    };
    const columns = [
      {
        title: "Mã yêu cầu",
        dataIndex: "code",
      },
      {
        title: "Tên khách hàng",
        dataIndex: "organization.fullname",
      },
      {
        title: "Mã số/MST",
        dataIndex: "organization.code",
      },
      {
        title: "Đối tượng",
        dataIndex: "",
        render: (e) => {
          if (e) {
            if (Number(e.object) === 1) {
              return <Tag color="green">Cấp mới</Tag>;
            } else if (Number(e.object) === 2) {
              return <Tag color="orange">Gia hạn</Tag>;
            } else if (Number(e.object) === 3) {
              return <Tag color="orange">Chuyển đổi</Tag>;
            } else if (Number(e.object) === 4) {
              return <Tag color="orange">Thay đổi thông tin</Tag>;
            } else if (Number(e.object) === 5) {
              return <Tag color="orange">Cấp lại</Tag>;
            } else {
              return <Tag color="red">Bảo hành</Tag>;
            }
          }
        },
      },
      {
        title: "Gói dịch vụ",
        dataIndex: "cateservicespackage.name",
      },
      {
        title: "Thời gian tạo",
        dataIndex: "created_at",
        render: (text, record, index) => formatDateTime(text),
      },
      {
        title: "Ngày hiệu lực",
        dataIndex: "info_cert.certificateBegin",
        render: (text, record, index) => formatDateNotTime(text),
      },
      {
        title: "Ngày hết hạn",
        dataIndex: "info_cert.certificateEnd",
        render: (text, record, index) => formatDateNotTime(text),
      },
      {
        title: "Tên tài khoản",
        dataIndex: "",
        render: (e) => {
          return e.user.fullname;
        },
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        render: renderStatusColumn,
      },
      {
        title: "Thời gian duyệt",
        dataIndex: "nv_approve_at",
        render: (text, record, index) => formatDateTime(text),
      },
      {
        title: "Mã bảo mật",
        dataIndex: "",
        render: (e) => {
          if (e.created_by === user.id) {
            if (Number(e.status) === 6 && e.secret_code) {
              return e.secret_code;
            }
          }
        },
      },
      {
        title: "Tác vụ",
        render: renderActionButton,
      },
    ];
    const fetch = async (params = {}) => {
        try {
            return await RequestCertificateGroupService.getListIndex(params);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const [modalImport, setModalImport] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });
    const [fileName, setFileName] = useState("");
    const [id] = useState();
    const handleModalImportCancel = () => {
        setModalImport({ ...modalImport, 'visible_modal_deny': false })
    };
    const confirmImport = async () => {
        if (!fileName) {
            setModalImport({ ...modalImport, 'error_text_deny': "Bạn chưa tải file hồ sơ lên" });
            return false;
        }
        const data = { file: fileName, id: id };
        const result = await RequestCertificateGroupService.uploadFile(data);
        await setModalImport({ ...modalImport, 'visible_modal_deny': false });
        if (result && Number(result.status) === 200) {
            onSuccessAction("Tải dữ liệu thành công!", () => {
                props.history.push("/yeu-cau-cts-to-chuc");
            });
        } else if (result && Number(result.status) === 404) {
            onFailAction("File hồ sơ ko đúng mẫu!");
        } else {
            onFailAction(result.error, () => {
                props.history.push("/yeu-cau-cts-to-chuc");
                setFileName("");
            });
        }
    };
    const onChangeDataDeny = ({ target: { value } }) => {
        setModalImport({ ...modalImport, 'text_deny': value });
    };
    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const fileSize = files.size / 1024 / 1024;
            if (fileSize > 5.1) {
                setModalImport({ ...modalImport, 'error_text_deny': "File tải lên không thể lớn hơn 5MB" });
                return false;
            } else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    setFileName(fileData.file);
                }
                loading.stopRunLoading();
            }

        } else {
            setFileName("");
        }
    };
    // const importClick = async id => {
    //     setModalImport({ ...modalImport, 'visible_modal_deny': true });
    //     setID(id);
    // };

    return (
        <PageWrapper title="Danh sách yêu cầu chứng thư số tổ chức">
            <WrappedRequestCTSGroupSearchForm />
            <Table columns={columns} onFetchData={fetch} />
            <ModalUploadFile
                visible={modalImport.visible_modal_deny}
                handleCancel={handleModalImportCancel}
                handleImport={confirmImport}
                value={modalImport.text_deny}
                onChange={onChangeDataDeny}
                error={modalImport.error_text_deny}
                onchangeFileUpload={onChangeFile}
                filename={fileName}
            />
        </PageWrapper>
    );
};
