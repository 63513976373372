import {Tag} from "antd";
import TableActionButton from "components/common/table/action-button/TableActionButton";
import {onFailAction} from "helpers/SwalCommon";
import React from "react";
import {RouteComponentProps} from "react-router";
import Table from "../../components/common/table/Table";
import {RequestRenewAndWarrantyServices} from "../../services/request-renew-warranty/RequestRenewAndWarrantyServices";
import PageWrapper from "../wrapper/PageWrapper";
import {formatDateTime} from '../../helpers/NewCaCrmHelper';
import WrappedSearchForm from "./RequestRenewAndWarrantySearchForm";
import {STATUS_LABEL_CLASS} from "./enum/RequestRenewAndWarrantyEnum";

interface Props extends RouteComponentProps {
    history: any;
}

export const RequestRenewAndWarrantyList: React.FC<Props> = props => {


    const renderActionButton = (text, record) => {
        return (
            <TableActionButton
                onClickPreviewButton={() => props.history.push(`/yeu-cau-cap-lai-bao-hanh/chi-tiet/${record.id}`)}

                // onClickUpdate={
                //     Number(record.status) === 2 ?
                //         Number(record.type) === 1
                //             ? () => props.history.push(`danh-sach-dieu-chinh-thong-tin/cap-nhat-to-chuc/${record.id}`)
                //             : () => props.history.push(`danh-sach-dieu-chinh-thong-tin/cap-nhat-ca-nhan/${record.id}`)
                //         : ''}

                // onClickDelete={
                //     [2, 4].indexOf(record.status) !== -1
                //         ? () => onDeleteRequest(record.id)
                //         : null
                // }
            />
        );
    };
    const columns = [
        {
            title: "Mã yêu cầu",
            dataIndex: "code_request"
        },
        {
            title: "Đối tượng",
            dataIndex: "type",
            render: (e)=>{
                if (e === 1){
                    return (<Tag color="cyan">Tổ chức</Tag>);
                }else {
                    return (<Tag color="volcano">Cá nhân</Tag>);
                }
            }
        },
        {
            title: 'Mã định danh',
            dataIndex: "identity",
        },
        {
            title: "Tên khách hàng",
            dataIndex: "fullname"
        },
        {
            title: "Số serial",
            dataIndex: "",
            render: (e) => {
                if (e.info_cert){
                    return e.info_cert.certificateSerial;
                }
                if (e.info_compensation_cert){
                    return e.info_compensation_cert.serial_cert;
                }
            }
        },
        {
            title: "Mã token",
            dataIndex: "",
            render: (e) =>{
                if (e.token_manager){
                    return e.token_manager.code;
                }
                if (e.info_compensation_cert){
                    return e.info_compensation_cert.token_code;
                }
            }
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "type_request",
            render : (e) => {
                if (e === 1){
                    return (<Tag color="green">Cấp lại</Tag>);
                }else {
                    return (<Tag color="volcano">Bảo hành</Tag>);
                }
            }
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_at",
            render: text => formatDateTime(text)
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            render: (e) => {
                return (
                    <Tag color={`${STATUS_LABEL_CLASS[e.status].class}`}>
                        {STATUS_LABEL_CLASS[e.status].label}
                    </Tag>
                );
            }
        },
        {
            title: "Mã bảo mật",
            dataIndex: "",
            render : (e) =>{
                if (Number(e.status) === 5){
                    return e.secret_code;
                }
            }
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            return await RequestRenewAndWarrantyServices.getListIndex(params);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Danh sách yêu cầu cấp lại - bảo hành">
            <WrappedSearchForm history={props.history}/>
            <Table columns={columns} onFetchData={fetch}/>
        </PageWrapper>
    );
};
